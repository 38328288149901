import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import { StaticLayout } from "../layouts/layouts"
import { HeadComponent } from "../components/components"
import dataProvider from "./home/_data"

import copy from "../locales/de.json"
import favicon from "../../static/logos/favicon.ico"
import previewPicture from "../../static/logos/campaxLogo.svg"

import PageComponent from "./home/__pagecomponent"

const RawPage = ({
  featuredCampaigns = [],
  allCampaigns = [],
  autoSuggestData = [],
}) => {
  const [pageMounted, mountPage] = useState(false)

  useEffect(() => {
    setTimeout(() => mountPage(true), 500)
  }, [])

  useEffect(() => {
    window.VIDEOASK_EMBED_CONFIG = {
      kind: "widget",
      url: "https://www.videoask.com/fua9lpdtb",
      options: {
        widgetType: "VideoThumbnailWindowTall",
        text: "",
        backgroundColor: "#15B4C4",
        position: "bottom-left",
        dismissable: false,
      },
    }
  }, [])
  return (
    <>
      <HeadComponent
        language="de"
        title={copy.metatags.title}
        shortDescription={copy.metatags.shortDescription}
        longDescription={copy.metatags.longDescription}
        keywords={copy.metatags.keywords}
        twitterAuthor={copy.metatags.twitterAuthor}
        favicon={favicon}
        previewPicture={previewPicture}
      />
      <StaticLayout
        header={copy.header}
        footerSocial={copy.footer.followUs}
        footerVersion={copy.footer.pickVersion}
        copyrights={copy.footer.copyrights}
        de={copy.footer.de}
        fr={copy.footer.fr}
        it={copy.footer.it}
        privacy={copy.footer.privacy}
        impressum={copy.footer.impressum}
        privacyLink={copy.footer.privacyLink}
        impressumLink={copy.footer.impressumLink}
      >
        <PageComponent
          pageMounted={pageMounted}
          featuredCampaigns={featuredCampaigns}
          allCampaigns={allCampaigns}
          autoSuggestData={autoSuggestData}
          copy={copy}
        />
      </StaticLayout>
    </>
  )
}

export const query = graphql`
  {
    allCampaign(
      filter: { slug: { ne: null }, acf: { language: { eq: "de" } } }
    ) {
      edges {
        node {
          id
          slug
          title {
            rendered
          }
          acf {
            cover_image
            cover_description
            featured
          }
        }
      }
    }
  }
`

RawPage.propTypes = {}

RawPage.defaultProps = {}

export default dataProvider(RawPage, "de")
